<template>
  <b-modal
    id="modal-create-workspace"
    ref="modal-create-workspace"
    centered
    title="Ajouter un espace de travail"
    @ok="create"
  >
    <div v-if="isWorkspaceCreating">
      <div class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement des détails du workspace...
      </div>
    </div>
    <validation-observer v-else ref="formCreate">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Label de l'espace de travail *"
              label-for="workspaceLabel"
            >
              <validation-provider
                #default="{ errors }"
                name="WorkspaceLabel"
                rules="required"
              >
                <b-form-input
                  id="label"
                  v-model="workspace.label"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right"
          @click="ok()"
        >
          Ajouter
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="float-right mr-1"
          @click="cancel()"
        >
          Annuler
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import "vue-select/dist/vue-select.css";
import VSwatches from "vue-swatches";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");
export default {
  data() {
    return {
      required,
      url,
      email,
      workspace: {
        label: "",
        status: true,
      },
    };
  },
  methods: {
    initializeForm() {
      this.workspace = {
        label: "",
        status: true,
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreate.validate().then((success) => {
        if (success) {
          this.createCompany({ customer: this.customer, that: this });
          this.$nextTick(() => {
            this.$refs["modal-create-customer"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions(["createCompany"]),
  },
  computed: {
    ...mapGetters(["isWorkspaceCreating", "createdDataWorkspace"]),
  },
  watch: {
    createdDataWorkspace: function () {
      if (this.createdDataWorkspace !== null && !this.isWorkspaceCreating) {
        this.$bvToast.toast(
          "L'espace de travail de \"" +
            this.createdDataWorkspace.label +
            '" a bien été créé',
          {
            title: `Création de l'espace de travail`,
            toaster: "b-toaster-bottom-right",
            solid: true,
            appendToast: true,
            variant: "success",
          }
        );
        this.initializeForm();
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
</style>