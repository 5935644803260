<template>
  <div>
    <b-row>
      <b-col class="content-header" cols="12">
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchCollaborator"
            v-model="searchWorkspace"
            placeholder="Rechercher un espace de travail"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <!-- <b-button
            variant="primary"
            @click="newCollaborator('')"
            class="px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            Nouveau collaborateur 
          </b-button> -->
          <!-- <vs-dropdown class="cursor-pointer">
            <div>
              <b-button
                variant="outline-primary"
                class="px-1"
              >
                  <feather-icon icon="SettingsIcon" />

              </b-button>
            </div>
            <vs-dropdown-menu class="w-full">
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="togglePanelColumn">{{this.gridOptions.sideBar == null? 'Gérer les colonnes' : 'Masquer la gestion des colonnes' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown> -->
        </div>
      </b-col>
    </b-row>
    <!-- <b-button variant="primary" class="mb-2" v-b-modal.modal-create-workspace
      >Ajouter</b-button
    > -->
    <!-- search input -->
    <div class="content-grid">
      <ag-grid-vue
        style="width: 100%; height: 100%;"
        class="ag-theme-material"
        :rowData="workspacesList"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        @rowClicked="onRowClicked"
      />
    </div>

    <!-- <popup-create-workspace />
    <popup-edit-workspace :id="idWorkspaceUpdate" /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AgGridVue } from 'ag-grid-vue'

import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton
} from 'bootstrap-vue'
import { agGridLocaleFR } from '@/data/agGridLocaleFR'

import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import PopupCreateWorkspace from '@/components/account/workspaces/popup-admin-workspace/PopupCreateWorkspace'
import PopupEditWorkspace from '@/components/account/workspaces/popup-admin-workspace/PopupEditWorkspace'
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    PopupCreateWorkspace,
    PopupEditWorkspace,
    AgGridVue
  },
  data () {
    return {
      searchWorkspace: '',
      gridOptions: {
        localeText: agGridLocaleFR,
        columnDefs: [{ headerName: 'Label', field: 'socialReason' }],
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 20
      }
    }
  },

  methods: {
    onRowClicked (elem) {
      console.log('rowClicked', elem)
          
      this.$store.dispatch('setWorkspaceSelected',  elem.data.id);
    },
    onFilterTextBoxChanged () {
      this.gridApi.setQuickFilter(this.searchWorkspace)
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.gridApi.setHeaderHeight(36)
    },
    ...mapActions(['fetchWorkspacesList'])
  },
  computed: {
    ...mapGetters(['workspacesList', 'isLoadingWorkspacesList', 'userInfo']),

    statusVariant () {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info'
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction () {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  created () {
    this.$store.dispatch('fetchWorkspacesList')
  }
}
</script>
